// import i18n from '@/i18n'
export const validatePassword = (loginForm) => {
  return (rule, value, callback) => {
    const { loginType, password } = loginForm
    if (value.length < 6) {
      if (loginType === 'register') {
        callback(new Error('密码不能少于6位'))
      } else {
        if (rule.field !== 'passwordAgain') {
          callback(new Error('密码不能少于6位'))
        } else {
          callback()
        }
      }
    } else {
      if (loginType === 'register') {
        if (value !== password) {
          callback(new Error('两次密码输入不一致'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
  }
}

export const validateProtocol = () => {
  return (rule, value, callback) => {
    if (!value) {
      callback(new Error('请阅读并勾选协议'))
    } else {
      callback()
    }
  }
}
