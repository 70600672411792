<template>
  <div class="protocol-dialog-box">
    <el-dialog
        v-model="baseData.protocolDialogShow"
        width="calc(100vw - 10vh)"
        top="5vh"
        align-center
        @close="$emit('close')"
    >
      <div class="card-box">
        <div class="card-hd">
          <div class="card-hd-left">
            科酷驴report服务协议和隐私政策
          </div>
          <div class="card-hd-right">
          </div>
        </div>
        <div class="card-bd">
          <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane label="用户协议" name="protocol1">
              <el-scrollbar>
                <p @click="emit('subCall')" v-for="i in 100" :key="i">用户协议</p>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="隐私政策" name="protocol2">
              <el-scrollbar>
                <p @click="emit('subCall')" v-for="i in 10" :key="i">隐私政策</p>
              </el-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, reactive, defineEmits } from 'vue'

const props = defineProps({
  isShow: Boolean,
  baseInfo: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['subCall'])

const baseData = reactive({
  protocolDialogShow: false
})

watch(() => props.isShow, (newVal) => {
  baseData.protocolDialogShow = newVal
})

// tabs
const activeName = ref('protocol1')

</script>

<style lang="scss">
@import '~@/styles/variables.scss';

.protocol-dialog-box {
  .el-dialog__header {
    display: none;
  }
  .el-dialog {
    border-radius: 8px;
  }
  .el-dialog__body {
    padding: 20px 0px 24px;
  }
  .card-box {
    margin-top: 0;
    margin-bottom: 0;
    .card-bd {
      padding: 0 24px;
    }
  }
  .el-tabs {
    width: 100%;
  }

  .el-scrollbar {
    height: calc(90vh - 160px);
  }
}

</style>
