<template>
  <div class="login-container">

    <div class="login-logo">
      <img :src="Logo"/>
      <p>科酷驴report</p>
    </div>
    <div class="login-main">
      <div class="login-banner">
        <svg-icon class="banner-login" icon="banner-login"/>
      </div>
      <div class="login-form-box">
        <el-form
          class="login-form"
          ref="loginFromRef"
          :model="loginForm"
          :rules="loginRules"
        >
          <div class="title-container">
            <h3 class="title">欢迎来到科酷驴report</h3>
            <!-- <lang-select class="lang-select"></lang-select> -->
          </div>

          <div class="login-tabs">
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
              <el-tab-pane label="登录" name="login"></el-tab-pane>
              <el-tab-pane label="注册" name="register"></el-tab-pane>
            </el-tabs>
          </div>

          <div class="login-info-box">
            <div class="login-input-box">
              <el-form-item prop="username">
                <el-input
                  placeholder="请输入用户名"
                  name="username"
                  type="text"
                  v-model="loginForm.username"
                  maxlength="30"
                  @focus="inputFocusHandle"
                  @blur="inputBlurHandle"
                  clearable
                />
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  placeholder="请输入密码"
                  name="password"
                  v-model="loginForm.password"
                  :type="passwordType"
                  maxlength="20"
                  @focus="inputFocusHandle"
                  @blur="inputBlurHandle"
                />
                <span class="showPwd">
                  <span class="svgContainer">
                    <svg-icon
                      :icon="passwordType === 'password' ? 'eye' : 'eye-open'"
                      @click="onChangePwdType"
                    />
                  </span>
                </span>
              </el-form-item>
              <el-form-item prop="passwordAgain" v-show="loginForm.loginType === 'register'">
                <el-input
                  placeholder="请确认密码"
                  name="passwordAgain"
                  v-model="loginForm.passwordAgain"
                  :type="passwordType"
                  maxlength="20"
                  @focus="inputFocusHandle"
                  @blur="inputBlurHandle"
                />
                <span class="showPwd">
                  <span class="svgContainer">
                    <svg-icon
                      :icon="passwordType === 'password' ? 'eye' : 'eye-open'"
                      @click="onChangePwdType"
                    />
                  </span>
                </span>
              </el-form-item>
              <!-- <el-form-item prop="tel">
                <el-input
                  placeholder="请输入手机号"
                  name="tel"
                  type="text"
                  maxlength="11"
                  v-model="loginForm.tel"
                  @focus="inputFocusHandle"
                  @blur="inputBlurHandle"
                />
              </el-form-item>
              <el-form-item prop="msCode">
                <div class="input-group">
                  <el-input
                    placeholder="请输入短信验证码"
                    name="code"
                    type="text"
                    maxlength="8"
                    v-model="loginForm.msCode"
                    @focus="inputFocusHandle"
                    @blur="inputBlurHandle"
                  />
                  <div class="input-group-btn"
                    :class="{'is-pass': (loginForm.tel.length === 11 && msCode.isSwitch)}"
                    @click="sendCodeHandle"
                  >{{ msCode.text }}</div>
                </div>
              </el-form-item> -->
              <el-form-item prop="picCode">
                <div class="input-group">
                  <el-input
                    placeholder="请输入图形验证码"
                    name="picCode"
                    type="text"
                    maxlength="8"
                    v-model="loginForm.picCode"
                    @focus="inputFocusHandle"
                    @blur="inputBlurHandle"
                  />
                  <img class="input-group-img"
                    :src="loginForm.picImg"
                    @click="sendImgHandle"
                  />
                </div>
              </el-form-item>
              <el-form-item prop="checkProtocol" class="login-protocol">
                <el-checkbox
                  v-model="loginForm.checkProtocol"
                  label="我已阅读并同意 "
                  size="large" />
                  <span @click="loginForm.protocolShow = true"> 科酷驴report服务协议和隐私政策</span>
              </el-form-item>
            </div>
            <div class="login-footer-box">
              <el-button
                type="primary"
                style="width: 100%; margin-bottom: 30px"
                :loading="loading"
                @click="handleLogin"
              >
                {{ loginForm.loginType === 'login' ? '登录' : '注册'}}
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <ProtocolDialog
      :isShow="loginForm.protocolShow"
      @close="loginForm.protocolShow = false"
      @subCall="checkProtocolHandle"
    ></ProtocolDialog>
  </div>
</template>

<script setup>
// import LangSelect from '@/components/LangSelect'
import { onMounted, ref, reactive } from 'vue'
import { validatePassword, validateProtocol } from './rules'
import { useStore } from 'vuex'
import { getVerifyCode } from '@/api/user'
// import { useI18n } from 'vue-i18n'
import ProtocolDialog from '@/components/GlobalDialog/protocolDialog.vue'
import Logo from '@/assets/xiaodianlv.png'
// 数据源
const loginForm = reactive({
  username: '',
  password: '',
  passwordAgain: '',
  tel: '',
  msCode: '',
  picCode: '',
  picCodeKey: '',
  picImg: '',
  checkProtocol: false,
  protocolShow: false,
  loginType: 'login' // register or login
})
// const msCode = reactive({
//   isSwitch: true,
//   text: '发送验证码',
//   timeZone: '',
//   timeCount: 10
// })
// 验证规则
// const i18n = useI18n()
const loginRules = ref({
  username: [
    {
      required: true,
      trigger: 'blur',
      message: '用户名为必填项'
    },
    {
      min: 4,
      max: 12,
      trigger: 'blur',
      message: '用户名长度在4到12位之间'
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword(loginForm)
    }
  ],
  passwordAgain: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword(loginForm)
    }
  ],
  tel: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入手机号'
    }
  ],
  msCode: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入验证码'
    }
  ],
  picCode: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入验证码'
    }
  ],
  checkProtocol: [
    {
      required: true,
      trigger: 'change',
      validator: validateProtocol()
    }
  ]
})

// tabs
const activeName = ref('login')
const handleClick = (tab) => {
  resetForm()
  loginForm.loginType = tab.props.name
}
// input 聚焦
const inputFocusHandle = (e) => {
  e.target.parentNode.classList.add('is-focus')
}
const inputBlurHandle = (e) => {
  e.target.parentNode.classList.remove('is-focus')
}
// 勾选协议
const checkProtocolHandle = () => {
  loginForm.checkProtocol = true
  loginForm.protocolShow = false
}

// 处理密码框文本显示状态
const passwordType = ref('password')
const onChangePwdType = () => {
  if (passwordType.value === 'password') {
    passwordType.value = 'text'
  } else {
    passwordType.value = 'password'
  }
}

// 登录动作处理
const loading = ref(false)
const loginFromRef = ref()
const store = useStore()
const handleLogin = () => {
  loginFromRef.value.validate((valid) => {
    if (!valid) return
    loading.value = true
    if (loginForm.loginType === 'register') {
      registerHandle()
    }
    if (loginForm.loginType === 'login') {
      loginHandle()
    }
  })
}
// 重置表单
const resetForm = () => {
  if (!loginForm) return
  loginFromRef.value.resetFields()
}
// 发送验证码事件
// const sendCodeHandle = () => {
//   if (loginForm.tel.length !== 11) return
//   timeCountDown()
// }
// const timeCountDown = () => {
//   msCode.isSwitch && (msCode.timeZone = setInterval(() => {
//     msCode.isSwitch = false
//     --msCode.timeCount
//     msCode.text = `(${msCode.timeCount}s)重新发送验证码`
//     if (msCode.timeCount <= 0) {
//       msCode.isSwitch = true
//       msCode.timeCount = 10
//       msCode.text = '发送验证码'
//       clearInterval(msCode.timeZone)
//       msCode.timeZone = null
//     }
//   }, 200))
// }

// 图形验证码事件
const sendImgHandle = async () => {
  const res = await getVerifyCode()
  loginForm.picImg = res?.captcha
  loginForm.picCode = res?.code
  loginForm.picCodeKey = res?.key
}

// 登录事件
const loginHandle = async () => {
  store
    .dispatch('user/login', loginForm)
    .then(() => {
      loading.value = false
      console.log('登录后操作')
    })
    .catch((err) => {
      console.log(err)
      loading.value = false
    })
}

// 注册事件
const registerHandle = async () => {
  store
    .dispatch('user/register', loginForm)
    .then(() => {
      loading.value = false
      console.log('注册后操作')
    })
    .catch((err) => {
      console.log(err)
      loading.value = false
    })
}

onMounted(() => {
  sendImgHandle()
  // 默认为注册态
  // if (activeName.value !== 10086) {
  //   activeName.value = 'register'
  //   loginForm.loginType = 'register'
  // }
})
</script>

<style lang="scss" scoped>
$bg: #ffffff;
$dark_gray: #c0c4cc;
$cursor: #8E82F6;
$themeColor: #8E82F6;

.login-container {
  height: calc(100vh - 40px);
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  background-image: radial-gradient(circle at 15% 40%,#F6EAFF 0,rgba(255,255,255,0) 30%),radial-gradient(circle at 40% 70%,#ECEDFE 0,rgba(255,255,255,0) 30%),radial-gradient(circle at 55% 40%,#FFF4F2 0,rgba(255,255,255,0) 30%),radial-gradient(circle at 90% 80%,#E9E3FC 0,rgba(255,255,255,0) 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .login-logo {
    position: absolute;
    top: 56px;
    left: 76px;
    display: flex;
    align-items: center;
    p {
      font-size: 22px;
      margin-left: 8px;
      color: #333333;
      font-weight: bold;
      letter-spacing: 1px;
    }
    img {
      width: 52px;
      height: 52px;
    }
  }
  .login-main {
    display: flex;
    align-items: start;
    .login-banner {
      width: 720px;
      height: 720px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      filter: hue-rotate(15deg);
      opacity: 0.9;
      margin-right: 160px;
      .banner-login {
        width: 100%;
        height: 100%;
      }
    }
    .login-form-box {
      border-radius: 24px;
      box-shadow: 0 40px 60px -43px #6F61E64d, 2px -3px 20px 0px #e7e7e74d;
      width: 360px;
      min-height: 540px;
      position: relative;
      width: 420px;
      max-width: 100%;
      padding: 48px 36px 36px;
      overflow: hidden;
      .login-form {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      :deep .el-form-item {
        // border: 1px solid $dark_gray;
        border: unset;
        border-radius: 8px;
        color: #333333;
        margin-bottom: 28px;
        &.is-error {
          box-shadow: 0 0 0 1px #f56c6c inset, 0 0 0 2px #f56c6c1a;
          .el-input__inner {
            box-shadow: none !important;
          }
          .el-form-item__error {
            padding-top: 6px;
          }
        }

      }

      :deep .el-input {
        overflow: hidden;
        display: inline-block;
        height: 47px;
        padding: 4px;
        --el-input-focus-border-color: none;
        --el-input-border-color: none;
        --el-input-hover-border-color: none;
        transition: all 0.3s;
        box-shadow: 0 0 0 0px #6F61E633 inset, 0 0 0 1px #6F61E633 !important;
        border-radius: 8px;
        &.is-focus {
          box-shadow: 0 0 0 1px #6F61E6 inset, 0 0 0 2px #6F61E633 !important;
        }
        input {
          background: transparent;
          border: 0px;
          -webkit-appearance: none;
          border-radius: 0px;
          padding: 12px 5px 12px 15px;
          color: #333333;
          height: 47px;
          caret-color: $cursor;
        }
      }

      :deep .login-tabs {
        margin: 0 0 12px 0;
        .el-tabs__item {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          padding-right: 0px;
          .is-active {
            color: #333333;
          }
        }
        .el-tabs__active-bar {
          height: 4px;
          border-radius: 16px;
        }
        .el-tabs__nav-wrap::after {
          display: none;
        }
      }
      .login-protocol {
        font-size: 16px;
        color: #6F61E6;
        display: flex;
        align-items: center;
        box-shadow: unset !important;
        span {
          cursor: pointer;
          height: 40px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .input-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &-btn {
      position: absolute;
      right: 0;
      width: 140px;
      padding-left: 16px;
      color: #a1a1a1;
      cursor: no-drop;
      &.is-pass {
        cursor: pointer;
        color: $themeColor;
      }
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background: #6F61E633;
        left: 0;
      }
    }
    &-img {
      position: absolute;
      right: 2px;
      width: 140px;
      padding-left: 16px;
      height: 51px;
      width: 138px;
      cursor: pointer;
      border-radius: 8px;
    }
  }
  .svgContainer {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 20px;
      color: #333333;
      margin: 0px auto 12px auto;
      text-align: left;
      font-weight: bold;
    }
  }

  .showPwd {
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  :deep .lang-select {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 22px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }
  .el-button {
    border-radius: 8px;
    height: 55px;
  }
}
</style>
